import Splide from '@splidejs/splide';

export default (el, { }, { cleanup }) => {

    let splide = new Splide(el, {
        arrows: true,
        drag: true,
        mediaQuery: 'min',
        breakpoints: {
            1024: {
                drag: false,
            },
        }
    })

    splide.on('mounted', () => {
        // Livewire.rescan()
    })

    splide.mount()


    // cleanup(() => {
    //     elm.destroy(true)
    // })

}