// import 'overlayscrollbars/overlayscrollbars.css';
// import {
//     OverlayScrollbars,
//     ScrollbarsHidingPlugin,
//     SizeObserverPlugin,
//     ClickScrollPlugin
// } from 'overlayscrollbars';
// import { Livewire, Alpine } from '../../../../vendor/livewire/livewire/dist/livewire.esm';

export default (key, savePosition) => ({

    position: Alpine.$persist(0).as(`scrollbar_position_${key}`),

    scrollbar: null,

    init() {
        this.$el.scroll({
            top: this.position,
            left: 0,
        });

        this.$el.addEventListener("scroll", () => {
            if (!savePosition) {
                return
            }

            this.position = this.$el.scrollTop
        });

        // return;

        // if (this.$el.hasAttribute("data-overlayscrollbars")) {
        //     return
        // }

        // OverlayScrollbars(this.$el, {
        //     overflow: {
        //         x: 'hidden',
        //     },
        // }, {
        //     initialized: (instance) => {
        //         const { viewport } = instance.elements()
        //         viewport.scrollTo({ top: this.position })

        //         // Alpine initiieren
        //         // Alpine.start()

        //         setTimeout(() => {
        //             Livewire.rescan()
        //             console.log('fire!', Livewire.rescan())
        //         }, 5000)
        //     },
        //     scroll: (instance, event) => {
        //         this.position = instance.elements().viewport.scrollTop
        //     }
        // })

        // this.scrollbar = 'true'
    }
})