import _debounce from 'lodash.debounce'

export default (param) => ({

    url: param.url,

    site: param.site,
    
    showNavigation: false,

    navigationCloseTimeout: null,

    navigationOpenTimeout: null,

    forceOpenNavigation: false,

    showMobileSuche: false,

    showSearchResults: false,

    scrollToSpalteOnInitKey: 'scroll-to-spalte',

    scrollXPositionMobile: Alpine.$persist(0).as(`scroll_x_position_mobile`),

    init() {
        if (localStorage.getItem(this.scrollToSpalteOnInitKey)) {

            const params = JSON.parse(localStorage.getItem(this.scrollToSpalteOnInitKey))

            this.$nextTick(() => this.scrollToSpalte(params.spalte, params.unterseite, false))
            localStorage.removeItem(this.scrollToSpalteOnInitKey)
        }

        this.$watch('showNavigation', (value) => {
            if (!value) {
                this.showSearchResults = false
            }
        })

        this.$watch('showSearchResults', (value) => {
            this.forceOpenNavigation = value
        })

        this.handleScrollXPositionMobile()
    },

    showMenuNavigation(forceOpen = false) {

        if (this.isMobile()) {
            return
        }

        let callback = () => {
            this.$nextTick(() => this.showNavigation = true)

            if (forceOpen) {
                this.forceOpenNavigation = true
            }
        }

        if (this.isMobile()) {
            callback()
            return
        }

        this.navigationOpenTimeout = setTimeout(callback, 300)

        clearTimeout(this.navigationCloseTimeout)
    },

    mobileShowMenuNavigation() {
        if (!this.isMobile()) {
            return
        }

        this.showNavigation = true
    },

    hideMenuNavigation(forceClose = false) {

        if (this.isMobile()) {
            return
        }

        let callback = () => {
            if (forceClose) {
                this.showNavigation = false
                this.forceOpenNavigation = false
                this.showSearchResults = false
                return
            }

            if ((this.showSearchResults || this.forceOpenNavigation)) {
                return
            }

            this.$nextTick(() => this.showNavigation = false)
        }

        if (this.isMobile()) {
            callback()
            return
        }

        this.navigationCloseTimeout = setTimeout(callback, 300)

        clearTimeout(this.navigationOpenTimeout)
    },

    toggleMenuNavigation() {

        if (this.showNavigation && this.forceOpenNavigation) {
            this.hideMenuNavigation(true)
            return
        }

        if (!this.showNavigation) {
            this.showMenuNavigation(true)
        } else {
            this.forceOpenNavigation = true
        }
    },

    handleScrollXPositionMobile() {
        if (!this.isMobile()) {
            return
        }

        const mainContentElm = document.getElementById('main-content')

        if (this.scrollXPositionMobile > 0) {
            this.$nextTick(() => {
                mainContentElm.scroll({
                    left: this.scrollXPositionMobile,
                })

                // this.scrollXPositionMobile = 0
            })
        }

        mainContentElm
            .addEventListener('scroll', (event) => {
                this.scrollXPositionMobile = event.target.scrollLeft
            })

        window.addEventListener('resize', _debounce(() => this.scrollXPositionMobile = 0, 500))
    },

    scrollToSpalte(spalte, unterseite = false, smooth = true) {
        
        const elm = document.getElementById(`spalte-${spalte}`)
        
        if (window.location.pathname.replace(/\/(de|en)/g, '') !== '') {

            localStorage.setItem(this.scrollToSpalteOnInitKey, JSON.stringify({
                spalte,
                unterseite,
            }))

            window.location = this.url
            return
        }

        this.showNavigation = false

        const elmPosition = elm.getBoundingClientRect().left

        if (elmPosition !== 0 ) {
            document.getElementById('main-content').scrollTo({
                left: elmPosition,
                behavior: smooth ? 'smooth' : 'instant',
            })
        }
        console.log('okkk', unterseite)

        if (unterseite) {
            this.$wire.dispatch('change-seiten-art', { art: unterseite })
        }
    },

    goToSearch() {

        if (this.showNavigation) {
            this.showNavigation = false
            return
        }

        this.showNavigation = true
        
        this.$nextTick(() => document.getElementById('search-input').focus())
    },

    isMobile() {
        // https://tailwindcss.com/docs/responsive-design
        return window.innerWidth < 1024
    }
})