import '../bootstrap'

import './../CookieConsent'

import { Livewire, Alpine } from '../../../vendor/livewire/livewire/dist/livewire.esm';
import LazyLoad from 'vanilla-lazyload';
import Scroller from './modules/Scroller';
import App from './modules/App';
import Galerie from './modules/Galerie';

new LazyLoad()

Alpine.data('scrollbar', Scroller)

Alpine.data('app', App)

Alpine.directive('galerie', Galerie)

Livewire.start();